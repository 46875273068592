export const mockData = [
  {
    firstName: 'Mike',
    lastName: 'Johnson',
    role: 'Physician',
    locationHistory: {
      from: 'PACU',
      to: 'OR2',
      elapsedTime: '2h 30min',
    },
  },
  {
    firstName: 'Sarah',
    lastName: 'Smith',
    role: 'Nurse',
    locationHistory: {
      from: 'PACU',
      to: 'OR2',
      elapsedTime: '1h 15min',
    },
  },
  {
    firstName: 'John',
    lastName: 'Doe',
    role: 'Nurse',
    locationHistory: {
      from: 'PACU',
      to: 'OR2',
      elapsedTime: '45min',
    },
  },
  {
    firstName: 'Jane',
    lastName: 'Doe',
    role: 'Physician',
    locationHistory: {
      from: 'PACU',
      to: 'OR2',
      elapsedTime: '3h 10min',
    },
  },
  {
    firstName: 'Emily',
    lastName: 'Davis',
    role: 'Nurse',
    locationHistory: {
      from: 'PACU',
      to: 'OR2',
      elapsedTime: '2h 5min',
    },
  },
  {
    firstName: 'Michael',
    lastName: 'Brown',
    role: 'Physician',
    locationHistory: {
      from: 'PACU',
      to: 'Preop',
      elapsedTime: '1h 50min',
    },
  },
  {
    firstName: 'Chris',
    lastName: 'Garcia',
    role: 'Nurse',
    locationHistory: {
      from: 'PACU',
      to: 'Preop',
      elapsedTime: '30min',
    },
  },
  {
    firstName: 'Jessica',
    lastName: 'Martinez',
    role: 'Nurse',
    locationHistory: {
      from: 'Physician',
      to: 'PACU',
      elapsedTime: '4h 20min',
    },
  },
  {
    firstName: 'David',
    lastName: 'Hernandez',
    role: 'Nurse',
    locationHistory: {
      from: 'Waiting Room',
      to: 'PACU',
      elapsedTime: '3h 40min',
    },
  },
  {
    firstName: 'Linda',
    lastName: 'Lopez',
    role: 'Nurse',
    locationHistory: {
      from: 'PACU',
      to: 'Preop',
      elapsedTime: '2h 25min',
    },
  },
];
