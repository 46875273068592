import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  member: {
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
  },
  locationHistory: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  elapsedTime: {
    fontWeight: 'normal',
  },
  locationFrom: {
    whiteSpace: 'nowrap',
  },
  locationIn: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    padding: theme.spacing(1, 1.5),
  },
  inOr: {
    backgroundColor: theme.palette.success.dark,
    borderRadius: theme.shape.borderRadius,
  },
}));

export default useStyles;
