import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';
import PatientSlotAdditionalInfo from './PatientSlotAdditionalInfo';
import PatientSlotInfo from './PatientSlotInfo';

const PatientInfoWrapper = styled.div`
  flex: 1;
  overflow: hidden;

  ${props =>
    props.miniView &&
    css`
      min-height: 3.8em;
    `}
`;

const PatientInfo = ({
  status,
  physician,
  procedure,
  patient,
  patientId,
  patientType,
  patientData,
  bed,
  timeEntered,
  compactView,
  readyForOr,
  readyForSurgeon,
  readyInOr,
  roomName,
  familyReady,
  isHoldProcedure,
  isBlockNerve,
  expectedExitTime,
  size,
  allowWrap,
  miniView,
  preOpFocused,
  pacuFocused,
  showPreOpNote,
  preOpNote,
  showPacuNote,
  pacuNote,
  isDPU,
  showRoom,
  showDuration,
  blockNerveFollow,
  called,
  isPreOp,
  isSuperAdmin,
  hospitalId,
  noAdditionalInfo,
  messagingView,
  grow,
  readyForAnesthesia,
}) => (
  <PatientInfoWrapper miniView={miniView}>
    <PatientSlotInfo
      physician={physician}
      patient={patient}
      patientId={patientId}
      patientType={patientType}
      procedure={procedure}
      size={size}
      readyForSurgeon={readyForSurgeon}
      readyForOr={readyForOr}
      readyInOr={readyInOr}
      familyReady={familyReady}
      allowWrap={allowWrap}
      miniView={miniView}
      isHoldProcedure={isHoldProcedure}
      isBlockNerve={isBlockNerve}
      called={called}
      isDPU={isDPU}
      isPreOp={isPreOp}
      isSuperAdmin={isSuperAdmin}
      hospitalId={hospitalId}
      messagingView={messagingView}
      patientData={patientData}
      grow={grow}
      readyForAnesthesia={readyForAnesthesia}
    />
    {!noAdditionalInfo && !messagingView && (
      <PatientSlotAdditionalInfo
        bed={bed}
        procedure={procedure}
        timeEntered={timeEntered}
        compactView={compactView}
        status={status}
        size={size}
        miniView={miniView}
        roomName={roomName}
        expectedExitTime={expectedExitTime}
        preOpFocused={preOpFocused}
        pacuFocused={pacuFocused}
        showPreOpNote={showPreOpNote}
        showPacuNote={showPacuNote}
        preOpNote={preOpNote}
        pacuNote={pacuNote}
        showRoom={showRoom}
        showDuration={showDuration}
        blockNerveFollow={blockNerveFollow}
      />
    )}
  </PatientInfoWrapper>
);

PatientInfo.propTypes = {
  status: PropTypes.oneOf(['alert']),
  physician: PropTypes.string,
  procedure: PropTypes.string,
  patient: PropTypes.any,
  timeEntered: PropTypes.instanceOf(Date),
  compactView: PropTypes.bool,
  ready: PropTypes.bool,
  bed: PropTypes.string,
};

export default PatientInfo;
