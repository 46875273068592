import React, { useState } from 'react';
import PropTypes from 'prop-types';
import PatientSlotContainer from './PatientSlotContainer';
import PatientInfo from './PatientInfo';
import BedInfo from './BedInfo';
import { INTERNAL_MONITOR, PACU_TABLET, POST_OP_TABLET, PREP_TABLET } from '../../entities/screens/enums';
import Box from '@material-ui/core/Box';
import { createStyles, makeStyles } from '@material-ui/core';
import ChatButton from '../../chat/ChatButton';
import { CaretakerChatDialog } from '../../chat/ChatDialog';
import CaretakerMessage from './CaretakerMessage';

const useStyles = makeStyles(theme =>
  createStyles({
    chatButton: {
      paddingLeft: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
  })
);

const PatientSlot = ({
  caretakerMessage,
  status,
  physician,
  procedure,
  patient,
  patientId,
  patientType,
  bed,
  showPriority,
  priority,
  timeEntered,
  poseRef,
  preventAnimation,
  isFamilyReadyPreOp,
  ready,
  readyForSurgeon,
  readyForOr,
  isHoldProcedure,
  isBlockNerve,
  frameColor,
  familyReady,
  size,
  roomName,
  screenType,
  expectedExitTime,
  hideBedInfo,
  patientData,
  preOpFocused,
  showPreOpNote,
  preOpNote,
  showPacuNote,
  pacuNote,
  hideChart,
  isDPU,
  showRoom,
  showDuration,
  hospitalOverviewMode,
  blockNerveFollow,
  called,
  isPreOp,
  messagingView,
  readyForAnesthesia,
}) => {
  const classes = useStyles();
  const isInternalScreen = screenType === INTERNAL_MONITOR;
  const isPreOpScreen = screenType === PREP_TABLET;
  const isPacuScreen = screenType === PACU_TABLET;
  const isPostOpScreen = screenType === POST_OP_TABLET;
  const showChatIcon =
    !messagingView &&
    !!patientData?.notificationNumbers &&
    !!patientData?.caretakerConsent &&
    (isPacuScreen || isPreOpScreen || isPostOpScreen);

  return (
    <Box>
      <PatientSlotContainer
        status={status}
        ref={poseRef}
        preventAnimation={preventAnimation}
        border={(ready || isFamilyReadyPreOp) && !isDPU ? '#00B63E' : frameColor}
        size={size}
        messagingView={messagingView}
      >
        <PatientInfo
          physician={physician}
          patient={patient}
          patientId={patientId}
          patientType={patientType}
          procedure={procedure}
          timeEntered={timeEntered}
          ready={ready}
          readyForSurgeon={readyForSurgeon}
          readyForOr={readyForOr}
          familyReady={familyReady}
          isHoldProcedure={isHoldProcedure}
          isBlockNerve={isBlockNerve}
          bed={bed}
          size={size}
          roomName={roomName}
          expectedExitTime={expectedExitTime}
          showPreOpNote={showPreOpNote}
          showPacuNote={showPacuNote}
          preOpNote={preOpNote}
          pacuNote={pacuNote}
          hideChart={hideChart}
          isDPU={isDPU}
          showRoom={showRoom}
          showDuration={showDuration}
          blockNerveFollow={blockNerveFollow}
          called={called}
          isPreOp={isPreOp}
          noAdditionalInfo={messagingView}
          patientData={patientData}
          screenType={screenType}
          hospitalOverviewMode={hospitalOverviewMode}
          messagingView={messagingView}
          readyForAnesthesia={readyForAnesthesia}
        />
        <Box className={classes.chatButton}>
          {showChatIcon && (
            <ChatButton
              hasUnreadMessages={patientData?.caretakerThread?.hasUnreadMessages}
              chatComponent={CaretakerChatDialog}
              chatProps={{
                patient: patientData,
              }}
            />
          )}
        </Box>
        {isInternalScreen && caretakerMessage && !messagingView && (
          <CaretakerMessage phoneNumber={caretakerMessage?.phoneNumber} message={caretakerMessage?.content} />
        )}
        {!hideBedInfo && !messagingView && (
          <BedInfo preOpFocused={preOpFocused} bed={bed} showPriority={showPriority} priority={priority} size={size} />
        )}
      </PatientSlotContainer>
    </Box>
  );
};

PatientSlot.propTypes = {
  patient: PropTypes.string.isRequired,
};

export default PatientSlot;
