import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import PatientSlotDefault from './PatientSlotDefault';
import PatientSlotAlert from './PatientSlotAlert';

const PatientSlot = forwardRef(
  (
    {
      caretakerMessage,
      status,
      physician,
      procedure,
      patient,
      patientId,
      patientType,
      bed,
      showPriority,
      priority,
      timeEntered,
      preventAnimation,
      ready,
      isFamilyReadyPreOp,
      size,
      readyForSurgeon,
      readyForOr,
      isHoldProcedure,
      isBlockNerve,
      frameColor,
      familyReady,
      roomName,
      hideBedInfo,
      screenType,
      expectedExitTime,
      patientData,
      preOpFocused,
      showPreOpNote,
      showPacuNote,
      preOpNote,
      pacuNote,
      hideChart,
      isDPU,
      showRoom,
      showDuration,
      hospitalOverviewMode,
      blockNerveFollow,
      called,
      isPreOp,
      messagingView,
      readyForAnesthesia,
    },
    ref
  ) => {
    switch (status) {
      case 'alert':
        return (
          <PatientSlotAlert
            caretakerMessage={caretakerMessage}
            procedure={procedure}
            physician={physician}
            patient={patient}
            patientId={patientId}
            patientType={patientType}
            timeEntered={timeEntered}
            poseRef={ref}
            isFamilyReadyPreOp={isFamilyReadyPreOp}
            ready={ready}
            isHoldProcedure={isHoldProcedure}
            isBlockNerve={isBlockNerve}
            readyForSurgeon={readyForSurgeon}
            readyForOr={readyForOr}
            familyReady={familyReady}
            frameColor={frameColor}
            bed={bed}
            showPriority={showPriority}
            priority={priority}
            size={size}
            screenType={screenType}
            expectedExitTime={expectedExitTime}
            patientData={patientData}
            preOpFocused={preOpFocused}
            showPreOpNote={showPreOpNote}
            showPacuNote={showPacuNote}
            preOpNote={preOpNote}
            pacuNote={pacuNote}
            hideChart={hideChart}
            isDPU={isDPU}
            showRoom={showRoom}
            blockNerveFollow={blockNerveFollow}
            called={called}
            isPreOp={isPreOp}
            messagingView={messagingView}
            readyForAnesthesia={readyForAnesthesia}
          />
        );
      default:
        return (
          <PatientSlotDefault
            caretakerMessage={caretakerMessage}
            procedure={procedure}
            physician={physician}
            patient={patient}
            patientId={patientId}
            patientType={patientType}
            timeEntered={timeEntered}
            poseRef={ref}
            preventAnimation={preventAnimation}
            isHoldProcedure={isHoldProcedure}
            isBlockNerve={isBlockNerve}
            isFamilyReadyPreOp={isFamilyReadyPreOp}
            ready={ready}
            readyForSurgeon={readyForSurgeon}
            readyForOr={readyForOr}
            familyReady={familyReady}
            frameColor={frameColor}
            bed={bed}
            showPriority={showPriority}
            priority={priority}
            roomName={roomName}
            hideBedInfo={hideBedInfo}
            size={size}
            screenType={screenType}
            expectedExitTime={expectedExitTime}
            patientData={patientData}
            preOpFocused={preOpFocused}
            showPreOpNote={showPreOpNote}
            showPacuNote={showPacuNote}
            preOpNote={preOpNote}
            pacuNote={pacuNote}
            hideChart={hideChart}
            isDPU={isDPU}
            showRoom={showRoom}
            showDuration={showDuration}
            hospitalOverviewMode={hospitalOverviewMode}
            blockNerveFollow={blockNerveFollow}
            called={called}
            isPreOp={isPreOp}
            messagingView={messagingView}
            readyForAnesthesia={readyForAnesthesia}
          />
        );
    }
  }
);

PatientSlot.propTypes = {
  status: PropTypes.oneOf(['occupied', 'vacant', 'alert']),
  physician: PropTypes.string,
  procedure: PropTypes.string,
  patient: PropTypes.string,
  showDuration: PropTypes.bool,
  timeEntered: PropTypes.instanceOf(Date),
};

export default PatientSlot;
