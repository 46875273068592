import React from 'react';
import Box from '@material-ui/core/Box';
import { createStyles, Table, TableBody, TableCell, TableHead, TableRow, Theme } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { ArrowForward } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { mockData } from '@/components/pages/kiosk/monitor/StaffMonitor/mockData';
import useStyles from './StaffMontior.styles';

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(even)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

const TitleRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      borderBottomWidth: 3,
      borderBottomStyle: 'solid',
      borderColor: theme.palette.text.secondary,
    },
  })
)(TableRow);

const TitleCell = withStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(4),
    },
  })
)(TableCell);

const MemberTableRow = ({
  firstName,
  lastName,
  locationHistoryFrom,
  locationHistoryTo,
  elapsedTime,
  compact,
}: {
  firstName: string;
  lastName: string;
  locationHistoryFrom: string;
  locationHistoryTo: string;
  elapsedTime: string;
  compact?: boolean;
}) => {
  const classes = useStyles();

  return (
    <StyledTableRow>
      <TableCell size={compact ? 'small' : 'medium'}>
        <Typography variant="h3">
          {lastName}, {firstName}
        </Typography>
      </TableCell>
      <TableCell align="right" size={compact ? 'small' : 'medium'}>
        <Typography variant="h6" color="textSecondary" className={classes.locationFrom}>
          {locationHistoryFrom}
        </Typography>
      </TableCell>
      <TableCell style={{ width: 'fit-content' }} size={compact ? 'small' : 'medium'}>
        <ArrowForward />
      </TableCell>
      <TableCell size={compact ? 'small' : 'medium'}>
        <Typography
          variant="h3"
          className={clsx(classes.locationIn, {
            [classes.inOr]: locationHistoryTo === 'OR2' && !compact,
          })}
        >
          {locationHistoryTo}
        </Typography>
      </TableCell>
      <TableCell size={compact ? 'small' : 'medium'}>
        <Box className={classes.elapsedTime} px={1} py={0.5} display="flex" alignItems="center">
          <Typography variant="h6" component="span" className={classes.elapsedTime}>
            {elapsedTime}
          </Typography>
        </Box>
      </TableCell>
    </StyledTableRow>
  );
};

const TableSection = ({ title, role, compact }: { title?: string; role: string; compact?: boolean }) => (
  <>
    {title && (
      <TitleRow>
        <TitleCell colSpan={5}>
          <Typography variant="h6">{title}</Typography>
        </TitleCell>
      </TitleRow>
    )}
    {mockData
      .filter(item => item.role === role)
      .sort((a, b) => a.lastName.localeCompare(b.lastName))
      .map((member, index) => (
        <MemberTableRow
          key={index}
          firstName={member.firstName}
          lastName={member.lastName}
          locationHistoryFrom={member.locationHistory.from}
          locationHistoryTo={member.locationHistory.to}
          elapsedTime={member.locationHistory.elapsedTime}
          compact={compact}
        />
      ))}
  </>
);

const StaffMonitor = () => (
  <Box p={4}>
    <Table>
      <colgroup>
        <col style={{ width: '100%' }} />
        <col style={{ width: '20ch' }} />
        <col style={{ width: '0' }} />
        <col style={{ minWidth: '20ch' }} />
        <col style={{ minWidth: '18ch' }} />
      </colgroup>
      <TableHead>
        <TitleRow>
          <TableCell>
            <Typography variant="h6">Physicians</Typography>
          </TableCell>
          <TableCell>
            <Typography variant="h6" align="right">
              From
            </Typography>
          </TableCell>
          <TableCell />
          <TableCell>
            <Typography variant="h6">To</Typography>
          </TableCell>
          <TableCell>
            <Typography variant="h6">Elapsed Time</Typography>
          </TableCell>
        </TitleRow>
      </TableHead>
      <TableBody>
        <TableSection role="Physician" />
        <TableSection title="Staff" role="Nurse" compact />
      </TableBody>
    </Table>
  </Box>
);

export default StaffMonitor;
