import React, { FC, useContext, useState } from 'react';
import { format } from 'date-fns';
import TableWithColumnSelector, { OnClickFn, TableType } from '../../core/TableWithColumnSelector';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { useQuery } from '@apollo/client';
import {
  analyticsConfigQuery,
  physicianUtilizationAnalyticsQuery,
  physicianUtilizationAnalyticsTotalsQuery,
} from '../../../graph/dashboard';
import { PhysicianUtilizationTableData, PhysicianUtilizationTableTotalsData } from '../../../types/Analytics';
import { getMinutes } from './util';
import PhysicianPatientsAnalytics from './PhysicianPatientsAnalytics';
import Button from '@material-ui/core/Button';
import { ArrowBack } from '@material-ui/icons';
import { TimelineColumnLegend } from './components/ColumnLegend';
import { CircularProgress, Grid, Paper, useTheme } from '@material-ui/core';
import imagePIPO from '../../../assets/images/illustrations/info/PIPO.svg';
import imagePOPI from '../../../assets/images/illustrations/info/PHYSICIAN_POPI.svg';
import poToCut from '../../../assets/images/illustrations/info/po_to_cut.svg';
import startToClose from '../../../assets/images/illustrations/info/start_to_close.svg';
import piToPhyi from '../../../assets/images/illustrations/info/start_to_close.svg';
import closingCompleteToPatientOut from '../../../assets/images/illustrations/info/closing_complete_patient_out.svg';
import piToStart from '../../../assets/images/illustrations/info/patient_in_to_start.svg';
import { Bar, BarChart, Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip, XAxis } from 'recharts';
import DelaysBreakdown from './v2/DelaysBreakdown';
import { ChartWrapper } from './v2/PieCharts';
import PhysicianUtilizationChart from './v2/PhysicianUtilizationChart';
import CustomTooltipPhysician from './v2/charts/CustomTooltipPhysician';
import { useScope } from '../../../hooks/useScope';
import { PieChartData } from './v2/PieChart';
import { AggregationContext } from './Aggregation';
import AnalyticsPanel from './components/AnalyticsPanel';
import { pickFilter } from './GeneralAnalytics';
import { renderActiveShape } from './v2/charts/ORPerformancePieChart';
import { makeStyles } from '@material-ui/core/styles';

const toTableData = (
  data: PhysicianUtilizationTableData[],
  totals: PhysicianUtilizationTableTotalsData,
  onClick?: OnClickFn,
  hasPostOp?: boolean
): TableType => {
  const aggregation = useContext(AggregationContext);
  const aggregationTitle = aggregation === 'average' ? 'Avg.' : 'Med.';
  const postOpHeader = hasPostOp ? [`POSTOP ${aggregationTitle}`] : [];
  const baseHeaders = [
    'Physician',
    'Procedures',
    'Surgery Days',
    'Physician Turnover',
    'Ready for Anesthesia to Ready In PreOp',
    'Ready In PreOp to Wheels In',
    'Wheels in to Ready For Surgery',
    'Wheels In to Physician In',
    'Wheels In to Start',
    'Ready For Surgery to Physician In',
    'Ready For Surgery to Start',
    `Closing ${aggregationTitle}`,
    'Closing Complete to Wheels Out',
    'PO to Cut',
    'Physician OR Utilization Index',
    `WR ${aggregationTitle}`,
    `Pre OP ${aggregationTitle}`,
    'Wheels-In, Wheels-Out',
    'Start to Close',
    `Recovery ${aggregationTitle}`,
    ...postOpHeader,
    `Pre OP to Exit ${aggregationTitle}`,
    `FCOTS ${aggregationTitle}`,
    'OR Daily Usage Time',
    'Aggregate Daily Patients OR Time',
    'Pre Op to Exit Total',
    'Average Schedule Accuracy',
    'Scheduled Procedure Duration',
  ];

  const pipoOverE2EPercentage = totals?.pipoOverE2EPercentage?.toFixed(2)
    ? `${(totals?.pipoOverE2EPercentage * 100)?.toFixed(2)}%`
    : '-';
  const total = [
    totals?.physician || 'Total',
    totals?.numberOfProcedures?.toString() || '-',
    totals?.surgeryDays?.toString() || '-',
    getMinutes(aggregation === 'average' ? totals?.popiPhysicianAvg : totals?.popiPhysicianMed),
    getMinutes(
      aggregation === 'average' ? totals?.readyForAnesthesiaToReadyForORAvg : totals?.readyForAnesthesiaToReadyForORMed
    ),
    getMinutes(aggregation === 'average' ? totals?.patientReadyToOrInAvg : totals?.patientReadyToOrInMed),
    getMinutes(aggregation === 'average' ? totals?.inORToReadyForSurgeryAvg : totals?.inORToReadyForSurgeryMed),
    getMinutes(aggregation === 'average' ? totals?.piToPhyiAvg : totals?.piToPhyiMed),
    getMinutes(aggregation === 'average' ? totals?.piToStartAvg : totals?.piToStartMed),
    getMinutes(aggregation === 'average' ? totals?.readyForSurgeryToPhyiAvg : totals?.readyForSurgeryToPhyiMed),
    getMinutes(aggregation === 'average' ? totals?.readyForSurgeryToStartAvg : totals?.readyForSurgeryToStartMed),
    getMinutes(aggregation === 'average' ? totals?.closingAvg : totals?.closingMed),
    getMinutes(
      aggregation === 'average' ? totals?.closingCompleteToPatientOutAvg : totals?.closingCompleteToPatientOutMed
    ),
    getMinutes(aggregation === 'average' ? totals?.poToCutAvg : totals?.poToCutMed),
    pipoOverE2EPercentage,
    getMinutes(aggregation === 'average' ? totals?.wrAvg : totals?.wrMed),
    getMinutes(aggregation === 'average' ? totals?.preOpAvg : totals?.preOpMed),
    getMinutes(aggregation === 'average' ? totals?.pipoAvg : totals?.pipoMed),
    getMinutes(aggregation === 'average' ? totals?.startToCloseAvg : totals?.startToCloseMed),
    getMinutes(aggregation === 'average' ? totals?.recoveryAvg : totals?.recoveryMed),
    ...(hasPostOp ? [getMinutes(aggregation === 'average' ? totals?.postOpAvg : totals?.postOpMed)] : []),
    getMinutes(aggregation === 'average' ? totals?.preOpToExitAvg : totals?.preOpToExitMed),
    formatDurationInMinutes(aggregation === 'average' ? totals?.fcotsDelayAvg : totals?.fcotsDelayMed, 1),
    getMinutes(totals?.firstPIToLastPOTotal),
    getMinutes(totals?.pipoTotal),
    getMinutes(totals?.preOpToExitTotal),
    isFinite(totals?.scheduleEfficiency) ? Math.round(totals?.scheduleEfficiency * 100) + '%' : '-',
    getMinutes(
      aggregation === 'average' ? totals?.scheduledProcedureDurationAvg : totals?.scheduledProcedureDurationMed
    ),
  ];

  const totalRow = {
    id: 'total',
    columns: total,
  };

  const getSortableLastName = (fullName: string) => {
    if (!fullName) return '';

    const suffixes = ['Jr', 'Sr', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII', 'IX', 'X'];

    const parts = fullName.trim().split(/\s+/);

    if (parts.length > 1 && suffixes.includes(parts[parts.length - 1].replace(/[.,]/g, ''))) {
      return parts[parts.length - 2];
    }
    return parts[parts.length - 1];
  };

  const sortedData = [...(data || [])].sort((a, b) => {
    const lastNameA = getSortableLastName(a.physician);
    const lastNameB = getSortableLastName(b.physician);
    return lastNameA.localeCompare(lastNameB);
  });

  return {
    configHeader: [...baseHeaders],
    headers: [
      {
        id: 'h1',
        columns: [
          'Physician',
          'Procedures',
          'Surgery Days',
          {
            text: 'Physician Turnover',
            InfoComponent: () => (
              <TimelineColumnLegend
                graphic={imagePOPI}
                title="Physician Turnover"
                description="Time of Patient Procedure End to next patient First Cut, by surgeon"
              />
            ),
          },
          {
            text: 'Ready for Anesthesia to Ready In PreOp',
            InfoComponent: () => (
              <TimelineColumnLegend
                title="Ready for Anesthesia to Ready In PreOp"
                description="Time elapsed from the patient marked as Ready for Anesthesia in PreOp until patient marked as Ready for OR in PreOp"
              />
            ),
          },
          {
            text: 'Ready In PreOp to Wheels In',
            InfoComponent: () => (
              <TimelineColumnLegend
                title="Ready In PreOp to Patient In"
                description="Time elapsed from the patient marked as Ready for ORin PreOp until the patient's entry into the OR"
              />
            ),
          },
          {
            text: 'Wheels in to Ready For Surgery',
            InfoComponent: () => (
              <TimelineColumnLegend
                title="Patient in to Ready For Surgery"
                description="Time elapsed from the patient's entry into the OR until the patient marked as Ready for Surgery in OR"
              />
            ),
          },
          {
            text: 'Wheels In to Physician In',
            InfoComponent: () => (
              <TimelineColumnLegend
                title="Patient In to Physician In"
                description="Time elapsed from the patient's entry into the OR until the physician's entry into the OR"
              />
            ),
          },
          {
            text: 'Wheels In to Start',
            InfoComponent: () => (
              <TimelineColumnLegend
                graphic={piToStart}
                title="Patient In to Start"
                description="Time elapsed from the patient's entry into the OR until case start"
              />
            ),
          },
          {
            text: 'Ready For Surgery to Physician In',
            InfoComponent: () => (
              <TimelineColumnLegend
                title="Ready For Surgery to Physician In"
                description="Time elapsed from the patient marked as Ready For Surgery in OR until the physician's entry into the OR"
              />
            ),
          },
          {
            text: 'Ready For Surgery to Start',
            InfoComponent: () => (
              <TimelineColumnLegend
                title="Ready For Surgery to Start"
                description="Time elapsed from the patient marked as Ready For Surgery in OR until case start"
              />
            ),
          },
          `Closing ${aggregationTitle}`,
          {
            text: 'Closing Complete to Wheels Out',
            InfoComponent: () => (
              <TimelineColumnLegend
                graphic={closingCompleteToPatientOut}
                title="Closing Complete to Patient Out"
                description="Time from closing complete to patient out"
              />
            ),
          },
          {
            text: 'PO to cut',
            InfoComponent: () => (
              <TimelineColumnLegend
                graphic={poToCut}
                title="PO to cut"
                description="Time from surgeons previous cases patient out of room, to time of next procedures start time."
              />
            ),
          },
          {
            text: 'Physician OR Utilization Index',
            InfoComponent: () => (
              <Box p={2} width="30ch">
                <Typography variant="body2" color="textSecondary">
                  The ratio between Aggregate Daily Patients OR Time and OR Daily Usage Time.
                </Typography>
              </Box>
            ),
          },
          `WR ${aggregationTitle}`,
          `Pre OP ${aggregationTitle}`,
          {
            text: 'Wheels-In, Wheels-Out',
            InfoComponent: () => (
              <TimelineColumnLegend
                graphic={imagePIPO}
                title="Wheels-In, Wheels-Out"
                description={`${
                  aggregation === 'average' ? 'Average' : 'Median'
                } operating room time for patients under the care of the same physician. Operating room time is "wheels-in, wheels-out".`}
              />
            ),
          },
          {
            text: 'Start to Close',
            InfoComponent: () => (
              <TimelineColumnLegend
                graphic={startToClose}
                title="Start to Close"
                description="Time from case start to start of closing"
              />
            ),
          },
          `Recovery ${aggregationTitle}`,
          ...postOpHeader,
          `Pre OP to Exit ${aggregationTitle}`,
          `FCOTS ${aggregation === 'average' ? 'Average' : 'Median'}`,
          {
            text: 'OR Daily Usage Time',
            InfoComponent: () => (
              <Box p={2} width="30ch">
                <Typography variant="body2" color="textSecondary">
                  Time from the first patient entering the operating room to the time the last patient leaving the
                  operating room under the care of a specific physician.
                </Typography>
              </Box>
            ),
          },
          {
            text: 'Aggregate Daily Patients OR Time',
            InfoComponent: () => (
              <Box p={2} width="30ch">
                <Typography variant="body2" color="textSecondary">
                  Aggregate operating room time for all patients under the care of the same physician. Operating room
                  time is "wheels-in, wheels-out".
                </Typography>
              </Box>
            ),
          },
          'Pre Op to Exit Total',
          {
            text: 'Average Schedule Accuracy',
            InfoComponent: () => (
              <Box p={2} width="30ch">
                <Typography variant="body2" color="textSecondary">
                  Schedule Accuracy is calculated as: (1 − (|Scheduled Duration − Actual Duration| ÷ Scheduled
                  Duration)). It represents how close the actual time was to the scheduled time.
                </Typography>
              </Box>
            ),
          },
          'Scheduled Procedure Duration',
        ],
      },
    ],
    rows: sortedData
      .map(e => ({
        id: e?.id.toString(),
        columns: [
          e.physician,
          e.numberOfProcedures.toString(),
          e.surgeryDays.toString(),
          getMinutes(aggregation === 'average' ? e.popiPhysicianAvg : e.popiPhysicianMed),
          getMinutes(
            aggregation === 'average' ? e.readyForAnesthesiaToReadyForORAvg : e.readyForAnesthesiaToReadyForORMed
          ),
          getMinutes(aggregation === 'average' ? e.patientReadyToOrInAvg : e.patientReadyToOrInMed),
          getMinutes(aggregation === 'average' ? e.inORToReadyForSurgeryAvg : e.inORToReadyForSurgeryMed),
          getMinutes(aggregation === 'average' ? e.piToPhyiAvg : e.piToPhyiMed),
          getMinutes(aggregation === 'average' ? e.piToStartAvg : e.piToStartMed),
          getMinutes(aggregation === 'average' ? e.readyForSurgeryToPhyiAvg : e.readyForSurgeryToPhyiMed),
          getMinutes(aggregation === 'average' ? e.readyForSurgeryToStartAvg : e.readyForSurgeryToStartMed),
          getMinutes(aggregation === 'average' ? e.closingAvg : e.closingMed),
          getMinutes(aggregation === 'average' ? e.closingCompleteToPatientOutAvg : e.closingCompleteToPatientOutMed),
          getMinutes(aggregation === 'average' ? e.poToCutAvg : e.poToCutMed),
          `${(e.pipoOverE2EPercentage * 100).toFixed(2)}%`,
          getMinutes(aggregation === 'average' ? e.wrAvg : e.wrMed),
          getMinutes(aggregation === 'average' ? e.preOpAvg : e.preOpMed),
          getMinutes(aggregation === 'average' ? e.pipoAvg : e.pipoMed),
          getMinutes(aggregation === 'average' ? e.startToCloseAvg : e.startToCloseMed),
          getMinutes(aggregation === 'average' ? e.recoveryAvg : e.recoveryMed),
          ...(hasPostOp ? [getMinutes(aggregation === 'average' ? e.postOpAvg : e.postOpMed)] : []),
          getMinutes(aggregation === 'average' ? e.preOpToExitAvg : e.preOpToExitMed),
          formatDurationInMinutes(aggregation === 'average' ? e.fcotsDelayAvg : e.fcotsDelayMed, 1),
          getMinutes(e.firstPIToLastPOTotal),
          getMinutes(e.pipoTotal),
          getMinutes(e.preOpToExitTotal),
          isFinite(e?.scheduleEfficiency) ? Math.round(e?.scheduleEfficiency * 100) + '%' : '-',
          getMinutes(aggregation === 'average' ? e?.scheduledProcedureDurationAvg : e?.scheduledProcedureDurationMed),
        ],
        onClick,
      }))
      .concat([totalRow]),
  };
};

const toPieChartData = (totals: PhysicianUtilizationTableTotalsData): PieChartData => ({
  subtitle: isFinite(totals.numberOfProcedures)
    ? totals.numberOfProcedures === 1
      ? '1 procedure'
      : `${totals.numberOfProcedures} procedures`
    : '-',
  inOr: totals.inOrAvg,
  inSurgery: totals.inSurgeryAvg,
  closing: totals.closingAvg,
  procedureComplete: totals.procedureCompleteAvg,
  cleaning: totals.cleaningAvg,
  orReady: totals.orReadyAvg,
});

const COLORS = ['#00A7F7', '#2B3C87'];

export const CustomTooltip2 = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <Paper>
        <Box p={2}>
          <Typography gutterBottom>{payload[0].name}</Typography>
          {payload.map((p, i) => (
            <>
              <Box key={i} display="flex" alignItems="center" style={{ width: '16ch' }}>
                <Typography variant="h4">{`${Math.round(p.value)}%`}</Typography>
              </Box>
            </>
          ))}
        </Box>
      </Paper>
    );
  }

  return null;
};

const PhysicianUtilizationAnalytics: FC<{ filter: any; setFilter: any }> = ({ filter, setFilter }) => {
  const classes = useStyles();

  const dateRangeJSON = filter?.dateRange?.toJSON();
  const dateRange = { from: format(dateRangeJSON.from, 'YYYY-MM-DD'), to: format(dateRangeJSON.to, 'YYYY-MM-DD') };
  const { operationRooms, physicians, ...rest } = filter || {};

  const sanitizedOperationRoomsFilter =
    Array.isArray(operationRooms) && operationRooms.length > 0
      ? { operationRooms: operationRooms.map(or => parseInt(or, 10)) }
      : isFinite(parseInt(operationRooms, 10))
      ? { operationRooms: [parseInt(operationRooms, 10)] }
      : {};

  const sanitizedPhysiciansFilter =
    Array.isArray(physicians) && physicians.length > 0
      ? { physicians: physicians.map(py => parseInt(py, 10)) }
      : isFinite(parseInt(physicians, 10))
      ? { physicians: [parseInt(physicians, 10)] }
      : {};
  const analyticsConfig = useQuery(analyticsConfigQuery);

  const pipoMinThreshold = analyticsConfig?.data?.analyticsConfiguration?.pipoMinThreshold;
  const pipoMaxThreshold = analyticsConfig?.data?.analyticsConfiguration?.pipoMaxThreshold;
  const wrMinThreshold = analyticsConfig?.data?.analyticsConfiguration?.wrMinThreshold;
  const wrMaxThreshold = analyticsConfig?.data?.analyticsConfiguration?.wrMaxThreshold;
  const preOpMinThreshold = analyticsConfig?.data?.analyticsConfiguration?.preOpMinThreshold;
  const preOpMaxThreshold = analyticsConfig?.data?.analyticsConfiguration?.preOpMaxThreshold;
  const pacuMinThreshold = analyticsConfig?.data?.analyticsConfiguration?.pacuMinThreshold;
  const pacuMaxThreshold = analyticsConfig?.data?.analyticsConfiguration?.pacuMaxThreshold;
  const postOpMinThreshold = analyticsConfig?.data?.analyticsConfiguration?.postOpMinThreshold;
  const postOpMaxThreshold = analyticsConfig?.data?.analyticsConfiguration?.postOpMaxThreshold;
  const preOpToDischargedMinThreshold = analyticsConfig?.data?.analyticsConfiguration?.preOpToDischargedMinThreshold;
  const preOpToDischargedMaxThreshold = analyticsConfig?.data?.analyticsConfiguration?.preOpToDischargedMaxThreshold;
  const inOrMinThreshold = analyticsConfig?.data?.analyticsConfiguration?.inOrMinThreshold;
  const inOrMaxThreshold = analyticsConfig?.data?.analyticsConfiguration?.inOrMaxThreshold;
  const closingMinThreshold = analyticsConfig?.data?.analyticsConfiguration?.closingMinThreshold;
  const closingMaxThreshold = analyticsConfig?.data?.analyticsConfiguration?.closingMaxThreshold;
  const procedureCompleteMinThreshold = analyticsConfig?.data?.analyticsConfiguration?.procedureCompleteMinThreshold;
  const procedureCompleteMaxThreshold = analyticsConfig?.data?.analyticsConfiguration?.procedureCompleteMaxThreshold;
  const orReadyMinThreshold = analyticsConfig?.data?.analyticsConfiguration?.orReadyMinThreshold;
  const orReadyMaxThreshold = analyticsConfig?.data?.analyticsConfiguration?.orReadyMaxThreshold;
  const piToStartMinThreshold = analyticsConfig?.data?.analyticsConfiguration?.piToStartMinThreshold;
  const piToStartMaxThreshold = analyticsConfig?.data?.analyticsConfiguration?.piToStartMaxThreshold;
  const poToCutMinThreshold = analyticsConfig?.data?.analyticsConfiguration?.poToCutMinThreshold;
  const poToCutMaxThreshold = analyticsConfig?.data?.analyticsConfiguration?.poToCutMaxThreshold;
  const startToCloseMinThreshold = analyticsConfig?.data?.analyticsConfiguration?.startToCloseMinThreshold;
  const startToCloseMaxThreshold = analyticsConfig?.data?.analyticsConfiguration?.startToCloseMaxThreshold;
  const physicianPopiMinThreshold = analyticsConfig?.data?.analyticsConfiguration?.physicianPopiMinThreshold;
  const physicianPopiMaxThreshold = analyticsConfig?.data?.analyticsConfiguration?.physicianPopiMaxThreshold;
  const fcotsGracePeriod = analyticsConfig?.data?.analyticsConfiguration?.fcotsGracePeriod;
  const piToPhyiMinThreshold = analyticsConfig?.data?.analyticsConfiguration?.piToPhyiMinThreshold;
  const piToPhyiMaxThreshold = analyticsConfig?.data?.analyticsConfiguration?.piToPhyiMaxThreshold;
  const closingCompleteToPatientOutMinThreshold =
    analyticsConfig?.data?.analyticsConfiguration?.closingCompleteToPatientOutMinThreshold;
  const closingCompleteToPatientOutMaxThreshold =
    analyticsConfig?.data?.analyticsConfiguration?.closingCompleteToPatientOutMaxThreshold;

  const { data, loading } = useQuery(physicianUtilizationAnalyticsQuery, {
    variables: {
      filter: {
        dateRange: dateRangeJSON,
        ...sanitizedOperationRoomsFilter,
        ...sanitizedPhysiciansFilter,
        ...pickFilter(rest),
      },
      pipoMinThreshold,
      pipoMaxThreshold,
      wrMinThreshold,
      wrMaxThreshold,
      preOpMinThreshold,
      preOpMaxThreshold,
      pacuMinThreshold,
      pacuMaxThreshold,
      postOpMinThreshold,
      postOpMaxThreshold,
      preOpToDischargedMinThreshold,
      preOpToDischargedMaxThreshold,
      inOrMinThreshold,
      inOrMaxThreshold,
      closingMinThreshold,
      closingMaxThreshold,
      procedureCompleteMinThreshold,
      procedureCompleteMaxThreshold,
      orReadyMinThreshold,
      orReadyMaxThreshold,
      piToStartMinThreshold,
      piToStartMaxThreshold,
      poToCutMinThreshold,
      poToCutMaxThreshold,
      startToCloseMinThreshold,
      startToCloseMaxThreshold,
      physicianPopiMinThreshold,
      physicianPopiMaxThreshold,
      fcotsGracePeriod,
      piToPhyiMinThreshold,
      piToPhyiMaxThreshold,
      closingCompleteToPatientOutMinThreshold,
      closingCompleteToPatientOutMaxThreshold,
    },
    fetchPolicy: 'cache-and-network',
  });
  const scope: any = useScope();
  const hasPostOp = scope?.hospital?.modules?.hasPostop;
  const analyticsV2PieCharts = scope?.hospital?.modules?.analyticsV2PieCharts || false;
  const [physicianId, setPhysicianId] = useState<{
    idNumber: number;
    idString: string;
  } | null>(null);

  const { data: totalsData, loading: totalsLoading } = useQuery(physicianUtilizationAnalyticsTotalsQuery, {
    variables: {
      filter: {
        dateRange: dateRangeJSON,
        ...sanitizedOperationRoomsFilter,
        ...sanitizedPhysiciansFilter,
        ...pickFilter(rest),
      },
      pipoMinThreshold,
      pipoMaxThreshold,
      wrMinThreshold,
      wrMaxThreshold,
      preOpMinThreshold,
      preOpMaxThreshold,
      pacuMinThreshold,
      pacuMaxThreshold,
      postOpMinThreshold,
      postOpMaxThreshold,
      preOpToDischargedMinThreshold,
      preOpToDischargedMaxThreshold,
      inOrMinThreshold,
      inOrMaxThreshold,
      closingMinThreshold,
      closingMaxThreshold,
      procedureCompleteMinThreshold,
      procedureCompleteMaxThreshold,
      orReadyMinThreshold,
      orReadyMaxThreshold,
      piToStartMinThreshold,
      piToStartMaxThreshold,
      poToCutMinThreshold,
      poToCutMaxThreshold,
      startToCloseMinThreshold,
      startToCloseMaxThreshold,
      physicianPopiMinThreshold,
      physicianPopiMaxThreshold,
      fcotsGracePeriod,
      piToPhyiMinThreshold,
      piToPhyiMaxThreshold,
      closingCompleteToPatientOutMinThreshold,
      closingCompleteToPatientOutMaxThreshold,
    },
    fetchPolicy: 'cache-and-network',
  });
  const onPhysicianClick = (physicianId: string) => {
    const id = physicianId?.split('#')?.[0];
    setPhysicianId({ idNumber: Number(id), idString: physicianId });
  };

  const physicianUtilizationAnalyticsTotals = totalsData?.physicianUtilizationAnalyticsTotals;

  const table: TableType = toTableData(
    data?.physicianUtilizationAnalytics || [],
    physicianUtilizationAnalyticsTotals || [],
    onPhysicianClick,
    hasPostOp
  );

  const pieChartData = toPieChartData(
    data?.physicianUtilizationAnalytics || [],
    totalsData?.physicianUtilizationAnalyticsTotals || []
  );

  const onBack = (_: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setPhysicianId(null);
  };

  const theme = useTheme();

  if (physicianId) {
    const physicianAnalytics = (data?.physicianUtilizationAnalytics || []).find(e => e?.id === physicianId.idString);

    const fcotsDelayAvg = physicianAnalytics?.fcotsDelayAvg;
    const fcotsDelayDist = physicianAnalytics?.fcotsDelayDist;
    const fcotsDelayReasons = physicianAnalytics?.fcotsDelayReasons;
    const proceduresOnTimePercentage = physicianAnalytics?.proceduresOnTimePercentage;

    return (
      <>
        <Button variant="contained" startIcon={<ArrowBack />} onClick={onBack} style={{ marginBottom: 8 }}>
          Back to Physician Utilization Analytics
        </Button>

        {analyticsV2PieCharts && (
          <Grid container spacing={1} style={{ height: '100%', marginBottom: 8 }}>
            <Grid item lg={4}>
              <ChartWrapper title="Physician Utilization">
                <PhysicianUtilizationChart value={physicianUtilizationAnalyticsTotals?.pipoOverE2EPercentage || 0} />
              </ChartWrapper>
            </Grid>
            <Grid item lg={4}>
              <ChartWrapper
                title="FCOTS Delay"
                rightTitle="FCOTS %"
                header={
                  <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
                    <Typography variant="h1">{fcotsDelayAvg ? Math.round(fcotsDelayAvg) + ' min' : 'n/a'}</Typography>
                    <Typography variant="h3" align="right">
                      {proceduresOnTimePercentage ? Math.round(proceduresOnTimePercentage) + '%' : 'n/a'}
                    </Typography>
                  </Box>
                }
              >
                <ResponsiveContainer width="100%" height="100%" aspect={2.5}>
                  <BarChart data={fcotsDelayDist?.map((value, i) => ({ value, title: titles[i] })) ?? []}>
                    <Bar dataKey="value" fill="#00A7F7" />
                    <XAxis type="category" dataKey="title" stroke="rgba(255,255,255,0.25)" />
                  </BarChart>
                </ResponsiveContainer>
              </ChartWrapper>
            </Grid>
            <Grid item lg={4}>
              <ChartWrapper title="Delays">
                <Box display="flex" flexDirection="column">
                  <DelaysBreakdown reasons={fcotsDelayReasons ?? []} />
                </Box>
              </ChartWrapper>
            </Grid>
          </Grid>
        )}
        <PhysicianPatientsAnalytics
          physicianId={physicianId.idNumber}
          physicianName={physicianAnalytics?.physician}
          dateRange={dateRange}
          filter={{
            dateRange: dateRangeJSON,
            ...sanitizedOperationRoomsFilter,
            ...sanitizedPhysiciansFilter,
            ...pickFilter(rest),
          }}
        />
      </>
    );
  }

  if (loading) {
    return (
      <Box display="flex" alignItems="center" m={2} style={{ gap: '1em' }}>
        <CircularProgress size={30} />
        <Typography variant="body1" color="textSecondary">
          Loading…
        </Typography>
      </Box>
    );
  }

  const fcotsDelayAvg = totalsData?.physicianUtilizationAnalyticsTotals?.fcotsDelayAvg;
  const fcotsDelayDist = totalsData?.physicianUtilizationAnalyticsTotals?.fcotsDelayDist;
  const fcotsDelayReasons = totalsData?.physicianUtilizationAnalyticsTotals?.fcotsDelayReasons;
  const proceduresOnTimePercentage = totalsData?.physicianUtilizationAnalyticsTotals?.proceduresOnTimePercentage;

  const fcotsDelayDistWithPhysicians =
    fcotsDelayDist?.map((value, i) => {
      const physicianCount = {};

      const physicians =
        data?.physicianUtilizationAnalytics
          ?.filter(p => p.fcotsDelayDist?.[i] !== 0 && value !== 0 && p.fcotsDelayDist !== null)
          ?.map(p => p.physician) ?? [];

      physicians.forEach(physician => {
        const physicianData = data?.physicianUtilizationAnalytics?.find(p => p.physician === physician);
        if (physicianData) {
          physicianCount[physician] = physicianData.fcotsDelayDist?.[i];
        }
      });

      return {
        value,
        title: titles[i],
        physicians:
          physicians.map(physician => ({
            physician,
            count: physicianCount[physician],
          })) ?? [],
      };
    }) ?? [];

  const utilization = 50 * 100;

  const data2 = [
    { name: 'FCOTS', value: Math.round(proceduresOnTimePercentage) },
    { name: 'Rest', value: 100 - Math.round(proceduresOnTimePercentage) },
  ];

  return (
    <>
      {analyticsV2PieCharts &&
        (physicianUtilizationAnalyticsTotals || fcotsDelayAvg || fcotsDelayDist || fcotsDelayReasons) && (
          <Grid container spacing={1} style={{ marginBottom: 8 }}>
            <Grid item xs={12} md={3} style={{ display: 'flex' }}>
              <AnalyticsPanel title="Physician Utilization">
                <PhysicianUtilizationChart value={physicianUtilizationAnalyticsTotals?.pipoOverE2EPercentage || 0} />
              </AnalyticsPanel>
            </Grid>

            <Grid item xs={12} md={6} style={{ display: 'flex', flexDirection: 'column' }}>
              <Paper className={classes.root}>
                <Box className={classes.container}>
                  <Grid container style={{ flex: 1 }}>
                    <Grid item xs={12} lg={6} style={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography variant="subtitle2">
                        FCOTS %{' '}
                        <Typography component="span" color="textSecondary">
                          (Grace period {fcotsGracePeriod ? fcotsGracePeriod + 'min' : '0 min'})
                        </Typography>
                      </Typography>
                      <ResponsiveContainer width="100%" height="100%" aspect={2 / 1}>
                        <PieChart>
                          <Pie
                            startAngle={90}
                            endAngle={-270}
                            dataKey="value"
                            data={data2}
                            innerRadius="70%"
                            outerRadius="100%"
                            paddingAngle={1}
                            activeShape={props =>
                              renderActiveShape(
                                props,
                                `${proceduresOnTimePercentage ? Math.round(proceduresOnTimePercentage) : 'n/a'}%`,
                                'FCOTS %'
                              )
                            }
                            activeIndex={0}
                          >
                            {data2.map((entry, index) => (
                              <Cell
                                stroke="none"
                                style={{ outline: 'none' }}
                                key={`cell-${index}`}
                                fill={COLORS[index % COLORS.length]}
                              />
                            ))}
                            <Cell stroke="none" style={{ outline: 'none' }} fill="#00a7f7" />
                          </Pie>
                          <Tooltip
                            content={({ active, payload, label }) => (
                              <CustomTooltip2 active={active} payload={payload} label={label} />
                            )}
                            cursor={{ fill: 'rgba(255,255,255,0.15)' }}
                            contentStyle={{
                              backgroundColor: theme.palette.background.paper,
                              borderRadius: 4,
                              border: 0,
                            }}
                          />
                          <Legend
                            content={'Utilization'}
                            align="left"
                            verticalAlign="bottom"
                            iconType="circle"
                            iconSize={8}
                          />
                        </PieChart>
                      </ResponsiveContainer>

                      {/*<Typography variant="caption">{fcotsGracePeriod ? fcotsGracePeriod + 'min' : '0 min'}</Typography>*/}
                    </Grid>

                    <Grid item xs={12} lg={6} style={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography variant="subtitle2">FCOTS Delay</Typography>
                      <Typography component="div" variant="h3">
                        {fcotsDelayAvg ? Math.round(fcotsDelayAvg) + ' min' : 'n/a'}
                      </Typography>
                      <ResponsiveContainer width="100%" height="100%" aspect={2.5}>
                        <BarChart
                          data={
                            fcotsDelayDistWithPhysicians?.map((value, i) => ({
                              value: value?.value,
                              title: titles[i],
                              physicians: value.physicians,
                            })) ?? []
                          }
                        >
                          <Bar
                            dataKey="value"
                            fill="#00A7F7"
                            data={
                              fcotsDelayDistWithPhysicians?.map((value, i) => ({
                                value: value?.value,
                                title: titles[i],
                                physicians: value.physicians,
                              })) ?? []
                            }
                          />
                          <XAxis type="category" dataKey="title" stroke="rgba(255,255,255,0.25)" />
                          <Tooltip
                            content={({ active, payload, label }) => (
                              <CustomTooltipPhysician
                                active={active}
                                payload={payload}
                                label={label}
                                unit="case"
                                useTitle
                              />
                            )}
                            cursor={{ fill: 'rgba(255,255,255,0.15)' }}
                            contentStyle={{
                              backgroundColor: theme.palette.background.paper,
                              borderRadius: 4,
                              border: 0,
                            }}
                          />
                        </BarChart>
                      </ResponsiveContainer>
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </Grid>

            <Grid item xs={12} md={3} style={{ display: 'flex' }}>
              <AnalyticsPanel title="Delays">
                <DelaysBreakdown reasons={fcotsDelayReasons ?? []} />
              </AnalyticsPanel>
            </Grid>
          </Grid>
        )}
      <TableWithColumnSelector
        configName="physician-table-config"
        tableId="Physician-Analysis-table"
        tableName={`Analysis by Physician`}
        excelFileName={`Physician-Analysis-${format(dateRange.from, 'MM/DD/YYYY')}-${format(
          dateRange.to,
          'MM/DD/YYYY'
        )}`}
        {...table}
      />
    </>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flex: 1,
  },
  container: {
    flex: 1,
    display: 'flex',
    padding: theme.spacing(2, 1.5),
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
  },
  leftTitle: {
    flex: 1,
    textAlign: 'left',
  },
  centerTitle: {
    flex: 1,
    textAlign: 'center',
  },
  rightTitle: {
    flex: 1,
    textAlign: 'right',
  },
}));

export default PhysicianUtilizationAnalytics;

export const formatDurationInMinutes = (duration?: number, decimals: number = 0) => {
  if (duration === undefined) {
    return '-';
  }

  return Math.round(duration).toFixed(decimals) + ' ' + (duration === 1 ? 'min' : 'mins');
};

const titles = ['0–5m', '5–10m', '10–20m', '20–30m', '30m+'];
